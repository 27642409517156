import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { SidebarActive } from "../assets/svgs";
import { CBadge } from "@coreui/react";
import { StyledNavLink } from "./Styled";
import { useSelector } from "react-redux";
import performRequest from "src/common/network";
 import { useDispatch } from "react-redux";
 import { inspectionCounter } from "src/store/reducers/inspection.reducer";

 export const AppSidebarNav = ({ items }) => {
   const location = useLocation();
   const dispatch = useDispatch();
   const [pendingInspection, setPendingInspection] = useState("");
   const [completedInspection, setCompletedInspection] = useState("");
   const { userData } = useSelector((s) => s.user);
   const { pendingCounter, completedCounter } = useSelector(
     (s) => s.inspection
   );

   const { token } = useSelector((s) => s.user);
   useEffect(() => {
     console.log("inspectionCounter");
     dispatch(inspectionCounter());
   }, []);

   const navLink = (name, icon, badge) => {
     return (
       <>
         {icon && icon}
         {name && name}
         {(name === "Open Inspections" || name === "Completed Inspections") && (
           <CBadge color={"secondary"} className="ms-auto" shape="rounded-pill">
             {name === "Open Inspections"
               ? pendingCounter && pendingCounter > 0
                 ? pendingCounter
                 : ""
               : completedCounter && completedCounter > 0
               ? completedCounter
               : ""}
           </CBadge>
         )}

         <div className="active_svg">
           <SidebarActive />
         </div>
       </>
     );
   };

   const navItem = (item, index) => {
     const { component, name, badge, icon, ...rest } = item;
     const Component = component;
     return (
       <Component
         {...(rest.to &&
           !rest.items && {
             component: NavLink,
           })}
         key={index}
         {...rest}
       >
         {navLink(name, icon, badge)}
       </Component>
     );
   };

   const navGroup = (item, index) => {
     const { component, name, icon, to, ...rest } = item;
     const Component = component;
     return (
       <Component
         idx={String(index)}
         key={index}
         toggler={navLink(name, icon)}
         visible={location.pathname.startsWith(to)}
         className="sidebar-icon"
         {...rest}
       >
         {item.items?.map((item, index) =>
           item.items ? navGroup(item, index) : navItem(item, index)
         )}
       </Component>
     );
   };

   return (
     <React.Fragment>
       {items &&
         items.map((item, index) =>
           item?.items
             ? item?.name == "Admin"
               ? userData?.role == "Admin"
                 ? navGroup(item, index)
                 : ""
               : navGroup(item, index)
             : navItem(item, index)
         )}
     </React.Fragment>
   );
 };

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};

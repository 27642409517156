export const GlobalVar = {
  APIEndpoint: process.env.REACT_APP_API_KEY + '/api',
  ProfileImageEndpoint: process.env.REACT_APP_API_KEY + '/get-profile/',
  inspectionImageEndpoint: process.env.REACT_APP_API_KEY + '/inspectionimg/',
  insepectionPDF:process.env.REACT_APP_API_KEY + '/inspectionpdf/',
  insepectionReport:process.env.REACT_APP_API_KEY + '/inspectionreport/',
  colors: {
    primary: '#393a3d',
  },
}

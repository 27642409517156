import React, { useEffect, useState } from "react";
import { CCol, CRow, CListGroupItem, CAvatar } from "@coreui/react";
import { CListGroupStyled, CRowStyled, DashboardWrapperStyled } from "./Styled";
import AnalystCard from "../../components/AnalystCard/AnalystCard";
import { OpenInspectionsSvg, CompletedInspectionsSvg } from "../../assets/svgs";
import SimpleCard from "src/components/SimpleCard/SimpleCard";

import Inspections from "../Inspections/Inspections";
import performRequest from "src/common/network";
import { useDispatch, useSelector } from "react-redux";
import { GlobalVar } from "src/common/globalVar";
import { Avatar } from "../ManageUser/ManageUser";
import { logout } from "src/store/reducers/user.reducer";
import { useNavigate } from "react-router-dom";

const NewDashboard = () => {
  const [analytics, setAnalytics] = useState(null);
  const [precessed, setPrecessed] = useState([]);
  const { token } = useSelector((s) => s.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getAnalytics();
  }, []);

  const getAnalytics = async () => {
    try {
      if (token) {
        const res = await performRequest("admin/analytics", "", token, "GET");
        console.log(">>>>>>>>> res Data", res);
        if (res.error?.statusCode === 401) {
          dispatch(logout());
          navigate("/login");
        }
        if (res.body?.status) {
          setAnalytics(
            (res.body?.data?.completed || []).reduce(
              (a, b) => ({
                ...a,
                [b?.status]: b,
              }),
              {}
            )
          );
          setPrecessed(res.body?.data?.precessed);
        }
      }
    } catch (e) {
      console.log("abcd", e);
    }
  };

  const AnalystCardData = [
    {
      imgUrl: "/images/OpenInspectionsBg.png",
      bg: "#5451D9",
      isBgArrow: false,
      icon: <OpenInspectionsSvg />,
      iconBg: "#7674e1",
      text: "Open Inspections",
      number: `${
        (analytics?.["pending"]["totalCount"] || 0) +
        (analytics?.["submitted"]["totalCount"] || 0)
      }`,
      iconResSize: "18px",
    },
    {
      imgUrl: "/images/CompletedInspectionsBg.png",
      bg: "#83D031",
      isBgArrow: false,
      icon: <CompletedInspectionsSvg />,
      iconBg: "#99d953",
      text: "Completed Inspections",
      number: `${analytics?.["completed"]["totalCount"] || 0}`,
      iconResSize: "26px",
    },
  ];

  return (
    <DashboardWrapperStyled>
      {/* <div className="filter_card_wrapper">
        <SimpleCard>
          <div className="select_wrapper">
            <SimpleSelect options={yearSelect} width="265px" />
            <SimpleSelect options={monthSelect} width="265px" />
            <SimpleSelect options={weekSelect} width="265px" />
          </div>
        </SimpleCard>
      </div> */}

      <CRowStyled className="gy-4">
        {AnalystCardData.length > 0 &&
          AnalystCardData.map((item, index) => (
            <CCol key={index} xs={12} sm={6}>
              <AnalystCard
                imgUrl={item?.imgUrl}
                bg={item?.bg}
                isBgArrow={item?.isBgArrow}
                icon={item?.icon}
                iconBg={item?.iconBg}
                text={item?.text}
                number={item?.number}
                iconResSize={item?.iconResSize}
              />
            </CCol>
          ))}
      </CRowStyled>

      <CRow className="gy-4">
        <CCol xs={12} sm={6} md={12} lg={6}>
          <SimpleCard text="Completed Inspections">
            {analytics &&
              analytics["completed"]["users"]
                .sort((a, b) => b.count - a.count)
                .map((i, index) => {
                  return (
                    <CListGroupStyled>
                      <CListGroupItem style={{ marginBottom: 15 }}>
                        {i?.profilepic ? (
                          <CAvatar
                            src={GlobalVar.ProfileImageEndpoint + i.profilepic}
                          />
                        ) : (
                          <Avatar>
                            {" "}
                            <div className="user-img">
                              <div
                                className="avatar-img d-flex align-items-center justify-content-center"
                                style={{
                                  textTransform: "uppercase",
                                  fontSize: 14,
                                  border: "1px solid #E82225",
                                  color: "#E82225",
                                }}
                              >
                                {i?.fullname
                                  .split(" ")
                                  .map((n) => n[0])
                                  .join("")}
                              </div>
                            </div>
                          </Avatar>
                        )}
                        <span>
                          {i?.fullname} {i?.count || 0}
                        </span>
                      </CListGroupItem>
                    </CListGroupStyled>
                  );
                })}
          </SimpleCard>
        </CCol>
        <CCol>
          <SimpleCard text="Processed Reports">
            {precessed
              .sort((a, b) => b.totalCount - a.totalCount)
              .map((i, index) => {
                return (
                  <CListGroupStyled>
                    <CListGroupItem style={{ marginBottom: 15 }}>
                      {i?.users?.profilepic ? (
                        <CAvatar
                          src={
                            GlobalVar.ProfileImageEndpoint + i.users?.profilepic
                          }
                        />
                      ) : (
                        <Avatar>
                          {" "}
                          <div className="user-img">
                            <div
                              className="avatar-img d-flex align-items-center justify-content-center"
                              style={{
                                textTransform: "uppercase",
                                fontSize: 14,
                                border: "1px solid #E82225",
                                color: "#E82225",
                              }}
                            >
                              {i?.users?.fullname
                                .split(" ")
                                .map((n) => n[0])
                                .join("")}
                            </div>
                          </div>
                        </Avatar>
                      )}
                      <span>
                        {i?.users?.fullname} {i?.totalCount || 0}
                      </span>
                    </CListGroupItem>
                  </CListGroupStyled>
                );
              })}
          </SimpleCard>
        </CCol>
      </CRow>
      <div style={{ marginTop: "15px", marginBottom: "30px" }}>
        <SimpleCard text="Open Inspections">
          <Inspections type="open"></Inspections>
        </SimpleCard>
      </div>
    </DashboardWrapperStyled>
  );
};

export default NewDashboard;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import performRequest from "src/common/network";

const initialState = {
  pendingCounter: 0,
  completedCounter: 0,
};

export const inspectionCounter = createAsyncThunk("inspection", async () => {
  try {
    let userDta = JSON.parse(
      localStorage.getItem("persist:TJDistributors")
    ).user;
    let token = JSON.parse(userDta).token;
    if (token) {
      const pendingData = await performRequest(
        "inspection/inspectionlist?status=" + "pending",
        "",
        token,
        "GET"
      );
      const completedData = await performRequest(
        "inspection/inspectionlist?status=" + "completed",
        "",
        token,
        "GET"
      );
      if (!pendingData?.error) {
        if (!completedData?.error) {
          return { pending: pendingData?.body, completed: completedData?.body };
        } else throw pendingData?.error;
      } else throw pendingData?.error;
    }
  } catch (e) {
    throw e;
  }
});

const inspectionSlice = createSlice({
  name: "inspection",
  initialState,

  extraReducers: {
    [inspectionCounter.fulfilled]: (state, action) => {
      state.loading = false;
      //   set all state
      console.log("action?.payload", action);
      let responseData = action?.payload;
      if (responseData) {
        state.pendingCounter = action?.payload?.pending?.data?.totalDocs;
        state.completedCounter = action?.payload?.completed?.data?.totalDocs;
      }
    },
  },
  [inspectionCounter.pending]: (state) => {
    state.loading = true;
  },
  [inspectionCounter.rejected]: (state) => {
    state.loading = false;
  },
});

// export const { switchSiderBars } = inspectionSlice.actions;
export default inspectionSlice.reducer;

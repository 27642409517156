import { combineReducers } from 'redux'
import commonReducer from './common.reducer';
import userReducer from './user.reducer'
import inspectionReducer from "./inspection.reducer";

export const rootReducer = combineReducers({
  user: userReducer,
  inspection: inspectionReducer,
  common: commonReducer,
});
export default rootReducer;

import React from 'react'
import PropTypes from 'prop-types'

const CompletedInspectionsSvg = ({ height = '38', width = '38' }) => {
  return (
    <>
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.0704 32.9531C14.863 32.9524 12.6873 32.4281 10.722 31.4231C8.7567 30.4181 7.05786 28.9612 5.76507 27.172C4.47227 25.3828 3.6224 23.3125 3.28528 21.131C2.94815 18.9496 3.13339 16.7192 3.82578 14.6233C4.51817 12.5273 5.69795 10.6256 7.26819 9.07418C8.83844 7.52281 10.7543 6.36611 12.8585 5.69912C14.9627 5.03213 17.1951 4.87387 19.3723 5.23735C21.5495 5.60082 23.6095 6.47566 25.3829 7.79C25.6202 7.98287 25.774 8.25986 25.8122 8.56329C25.8504 8.86672 25.77 9.17318 25.5879 9.41885C25.4057 9.66451 25.1358 9.83043 24.8344 9.88205C24.533 9.93367 24.2233 9.867 23.9697 9.69594C21.8766 8.1419 19.3204 7.33935 16.7146 7.41808C14.1088 7.49681 11.6057 8.45222 9.61021 10.1298C7.61467 11.8074 6.24335 14.109 5.71801 16.6625C5.19267 19.216 5.54402 21.8721 6.71525 24.2012C7.88648 26.5303 9.8091 28.3962 12.1722 29.4972C14.5353 30.5982 17.2007 30.8699 19.7373 30.2683C22.274 29.6668 24.5335 28.2272 26.1506 26.1824C27.7677 24.1375 28.6478 21.607 28.6485 19C28.6468 18.3894 28.5972 17.78 28.5001 17.1772C28.4759 17.0212 28.4827 16.8621 28.52 16.7087C28.5574 16.5554 28.6245 16.4109 28.7177 16.2836C28.8109 16.1562 28.9282 16.0485 29.0631 15.9665C29.1979 15.8844 29.3476 15.8298 29.5035 15.8056C29.6594 15.7815 29.8186 15.7882 29.9719 15.8256C30.1253 15.8629 30.2697 15.9301 30.3971 16.0233C30.5245 16.1165 30.6322 16.2338 30.7142 16.3686C30.7962 16.5035 30.8509 16.6531 30.8751 16.8091C30.9812 17.5344 31.0308 18.2669 31.0235 19C31.0204 22.6996 29.5493 26.2468 26.9332 28.8629C24.3172 31.4789 20.77 32.95 17.0704 32.9531Z"
          fill="white"
        />
        <path
          d="M18.5547 23.4531C18.2403 23.4518 17.9392 23.3258 17.7176 23.1028L12.0769 17.4622C11.8824 17.235 11.7807 16.9428 11.7923 16.6439C11.8038 16.3451 11.9277 16.0616 12.1392 15.8501C12.3507 15.6386 12.6342 15.5147 12.933 15.5031C13.2319 15.4916 13.5241 15.5933 13.7513 15.7878L18.5547 20.5853L32.8582 6.2878C33.0853 6.09326 33.3776 5.9916 33.6764 6.00314C33.9753 6.01469 34.2588 6.13858 34.4703 6.35007C34.6818 6.56156 34.8057 6.84506 34.8172 7.14393C34.8288 7.44279 34.7271 7.73501 34.5326 7.96218L19.3919 23.1028C19.1702 23.3258 18.8692 23.4518 18.5547 23.4531Z"
          fill="white"
        />
      </svg>
    </>
  )
}
CompletedInspectionsSvg.propTypes = {
  height: PropTypes.any,
  width: PropTypes.any,
}

export default CompletedInspectionsSvg

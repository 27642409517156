import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import performRequest from "src/common/network";
import { SearchInput } from "src/components/CommonInputs";
import SearchBarCard from "src/components/SearchBarCard/SearchBarCard";
import TableComponent from "src/components/TableComponent/TableComponent";
import Pagination from "react-responsive-pagination";
import { ManageInspectionWrapper } from "../ManageInspectionTypes/Styled";
import moment from "moment";
import { toast } from "react-toastify";
import { GlobalVar } from "src/common/globalVar";
import { Avatar } from "../ManageUser/ManageUser";
import { CAvatar } from "@coreui/react";
import { useDispatch } from "react-redux";
import { inspectionCounter } from "src/store/reducers/inspection.reducer";

import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton,
} from "@coreui/react";
import useDebounce from "src/hooks/useDebounce";
import useDebounceFn from "src/hooks/useDebounceFn";
const Inspections = (props) => {
  const [dataList, _dataList] = useState([]);
  const dispatch = useDispatch();
  const { token } = useSelector((s) => s.user);

  const [searchParams, setSearchParams] = useSearchParams();

  const [offset, _offset] = useState(searchParams.get("offset") || 0);
  const [limit, _limit] = useState(searchParams.get("limit") || 10);
  const [total, _total] = useState(0);
  const [visible, _visible] = useState(false);
  const [deleteId, _deleteId] = useState("");
  const [searchData, _searchData] = useState(searchParams.get("search") || "");
  const [sort, setSort] = useState({ field: "_id", order: "desc" });
  const { pathname } = useLocation();
  const [paginationData, _paginationData] = useState({
    totalPages: 0,
    page: 0,
    nextPage: 0,
  });
  const navigate = useNavigate();
  const params = useParams();
  const debouncedSearch = useDebounce(searchData, 700);

  const updateParams = (obj) => {
    setSearchParams({
      offset,
      limit,
      sortfield: sort.field,
      sortorder: sort.order,
      search: searchData,
      ...obj,
    });
  };

  const updateParamsDebounced = useDebounceFn(updateParams, 700);

  const actionsHandler = (event) => {
    return (
      <>
        {/* <Link to="/addUser"> */}
        {/* <SecondaryButton onClick={() => setVisible(!visible)} text="Add Representative" /> */}
        {/* </Link> */}
        <SearchInput
          value={searchData}
          onChange={(e) => {
            _offset(0);
            _searchData(e.target.value);
            updateParamsDebounced({ offset: 0, search: e.target.value });
          }}
          onClear={() => {
            _offset(0);
            _searchData("");
            updateParamsDebounced({ offset: 0, search: e.target.value });
          }}
          onSubmit={() => {
            getInspectionList();
          }}
        />
      </>
    );
  };

  useEffect(() => {
    getInspectionList();
  }, [pathname, props?.type, offset, sort, debouncedSearch]);

  useEffect(() => {
    _offset(searchParams.get("offset") || 0);
    _searchData(searchParams.get("search") || "");
    _limit(searchParams.get("limit") || 10);
    setSort({
      field: searchParams.get("sortfield") || "_id",
      order: searchParams.get("sortorder") || "desc",
    });
  }, [pathname, searchParams]);

  const getInspectionList = async () => {
    try {
      if (token) {
        const status = props?.type ? [props.type] : pathname?.split("/");
        const searchParam =
          searchData !== "" ? `&search=${searchData || ""}` : "";
        const sortOrderParam = `&sortfield=${sort?.field || ""}&sortorder=${
          sort?.order || ""
        }`;

        const statusParam =
          status.length > 0
            ? status[status.length - 1] === "open"
              ? "pending"
              : "completed"
            : "";

        const endpoint = `inspection/inspectionlist?offset=${offset}&limit=${limit}&status=${statusParam}${searchParam}${sortOrderParam}`;

        const response = await performRequest(endpoint, "", token, "GET");

        if (response.body.status) {
          let EditedData = response?.body?.data?.docs?.map((item, index) => {
            console.log("itensssssss-=-=-=-=-=-", item);
            let temp = item;
            temp.date = moment(item?.createdAt).format("MM/DD/YYYY");
            temp.facalityName =
              item?.facalityName || item?.member?.facalityName;
            temp.inspectionType = item?.inspectionType?.name;
            var current = moment().startOf("day");
            let diff = Math.round(
              moment.duration(moment(temp.date).diff(current)).asDays()
            );
            temp.pastDays = diff < 0 ? diff * -1 : diff;
            {
              pathname !== "/inspections/completed"
                ? (temp.status = (
                    <button
                      style={{
                        color:
                          item?.status == "submitted" ? "#005BCC" : "#e2ae03",
                        border:
                          item?.status == "submitted"
                            ? "1px solid #005BCC"
                            : "1px solid #e2ae03",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item?.status == "submitted"
                        ? "Submitted"
                        : "In Progress"}
                    </button>
                  ))
                : "";
            }
            temp.action = (
              <div style={{ display: "flex" }}>
                <button
                  style={{
                    color: "#005BCC",
                    border: "1px solid #005BCC",
                    marginRight: "10px",
                  }}
                  onClick={() =>
                    navigate("/inspections/show-inspection/" + item?._id)
                  }
                >
                  Show
                </button>
                <button
                  style={{
                    color: item?.status == "completed" ? "#E82225" : "#1a800d",
                    border:
                      item?.status == "completed"
                        ? "1px solid #E82225"
                        : "1px solid #1a800d",
                  }}
                  onClick={() =>
                    statusChangeAction(
                      item?._id,
                      status[status?.length - 1] == "open"
                        ? "pending"
                        : "completed"
                    )
                  }
                >
                  {" "}
                  {status[status?.length - 1] == "open"
                    ? "Complete"
                    : "Pending"}
                </button>
                <button
                  style={{
                    color: "#e82225",
                    border: "1px solid #e82225",
                    marginLeft: "10px",
                  }}
                  onClick={() => {
                    _visible(true);
                    _deleteId(item?._id);
                  }}
                >
                  Delete
                </button>
              </div>
            );
            temp.user = item?.createdBy?.profilepic ? (
              <div className="d-flex align-items-center">
                <Avatar>
                  <CAvatar
                    width={32}
                    height={32}
                    className="user-img"
                    src={
                      GlobalVar.ProfileImageEndpoint +
                        item?.createdBy?.profilepic || userImgs
                    }
                  ></CAvatar>
                </Avatar>{" "}
                {item?.createdBy?.firstName}{" "}
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <Avatar>
                  {" "}
                  <div className="user-img">
                    <div
                      className="avatar-img d-flex align-items-center justify-content-center"
                      style={{
                        textTransform: "uppercase",
                        fontSize: 14,
                        border: "1px solid #E82225",
                        color: "#E82225",
                      }}
                    >
                      {item?.createdBy?.firstName.split(" ")[0].charAt(0)}
                      {item?.createdBy?.firstName?.split("")?.length > 1
                        ? item?.createdBy?.firstName?.split(" ")[1]?.charAt(0)
                        : ""}
                    </div>
                  </div>
                </Avatar>{" "}
                {item?.createdBy?.firstName}{" "}
              </div>
            );
            return temp;
          });
          _dataList(EditedData);
          _total(response.body.data.totalDocs || 0);

          _paginationData({
            nextPage: response.body.data.nextPage,
            page: response.body.data.page,
            totalPages: response.body.data.totalPages,
          });
        } else {
          token && toast.error("Something went wrong. Try again");
        }
      }
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong. Try again");
    }
  };

  const statusChangeAction = async (id, status) => {
    const res = await performRequest(
      "inspection/inspectionlist/" + id,
      { status: status == "pending" ? "completed" : "pending" },
      token,
      "PUT"
    );
    if (res.body.status) {
      getInspectionList();
    }
  };
  const deleteInspection = async (id) => {
    const res = await performRequest(
      "inspection/inspectionlist/" + id,
      "",
      token,
      "DELETE"
    );
    if (res.body) {
      _visible(false);
      dispatch(inspectionCounter());
      getInspectionList();
    }
  };

  const phoneFormatter = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + " " + match[3];
    }
    return phoneNumberString;
  };
  const tableColumn = [
    {
      dataField: "serviceRequest",
      text: "#",
      sort: true,
      headerStyle: () => {
        return { width: "15%" };
      },
      onSort: (field, order) => {
        updateParams({ sortfield: field, sortorder: order });
      },
      sortFunc: (a, b, order, field) => {},
    },
    {
      dataField: "customerName",
      text: "Customer",
      sort: true,
      headerStyle: () => {
        return { width: "15%" };
      },
      onSort: (field, order) => {
        updateParams({ sortfield: field, sortorder: order });
      },
      sortFunc: (a, b, order, field) => {},
    },

    {
      dataField: "facalityName",
      text: "Facility Name",
      sort: false,
      align: "left",
      headerStyle: () => {
        return { width: "30%", align: "left" };
      },
    },
    {
      dataField: "inspectionType",
      text: "Inspection Type",
      sort: false,
      align: "left",
      headerStyle: () => {
        return { width: "30%", align: "left" };
      },
    },
    {
      dataField: "date",
      text: "Create Date",
      sort: true,
      headerStyle: () => {
        return { width: "20%" };
      },
      onSort: (field, order) => {
        updateParams({ sortfield: field, sortorder: order });
      },
      sortFunc: (a, b, order, field) => {},
    },
    {
      dataField: "pastDays",
      text: "Days",
      sort: false,
      headerStyle: () => {
        return { width: "20%" };
      },
    },
    {
      dataField: "user",
      text: "Inspector",
      sort: false,
      headerStyle: () => {
        return { width: "20%" };
      },
    },
    {
      ...(pathname !== "/inspections/completed"
        ? {
            dataField: "status",
            text: "Status",
            sort: true,
            headerStyle: () => {
              return { width: "20%" };
            },
            onSort: (field, order) => {
              updateParams({ sortfield: field, sortorder: order });
            },
            sortFunc: (a, b, order, field) => {},
          }
        : {}),
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      headerStyle: () => {
        return { width: "15%" };
      },
    },
  ];
  return (
    <>
      {!props?.type ? (
        <>
          <SearchBarCard
            text="Inspections"
            actions={actionsHandler()}
          ></SearchBarCard>
          <div style={{ height: "30px" }}></div>
        </>
      ) : (
        <></>
      )}
      <TableComponent tableColumn={tableColumn} tableData={dataList} />
      {/* {!props?.type ? */}
      <CModal
        visible={visible}
        onClose={() => _visible(false)}
        backdrop="static"
      >
        <CModalHeader onClose={() => _visible(false)}>
          <CModalTitle>Delete Confirmation</CModalTitle>
        </CModalHeader>
        <CModalBody>Are you sure you want to delete?</CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => _visible(false)}>
            Close
          </CButton>
          <CButton color="danger" onClick={() => deleteInspection(deleteId)}>
            Delete
          </CButton>
        </CModalFooter>
      </CModal>
      <ManageInspectionWrapper>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <p className="mb-0">
              {total} Inspections (showing {+offset + 1}-
              {+offset + +limit < total ? +offset + +limit : total})
            </p>
          </div>
          <Pagination
            current={paginationData.page}
            total={paginationData.totalPages}
            onPageChange={(d) => {
              updateParams({ offset: (+d - 1) * limit });
            }}
          />
        </div>
      </ManageInspectionWrapper>
      {/* : <></>} */}
    </>
  );
};

export default Inspections;

import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
  CImage,
  CButton,
  CModalBody,
  CInputGroup,
  CModalFooter,
  CRow,
  CCol,
  CFormInput,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import { AppSidebarNav } from "src/components/AppSidebarNav";
import SidebarLogo from "../../assets/brand/SidebarLogo.png";
import { CModal, CModalHeader, CModalTitle } from "@coreui/react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { Link } from "react-router-dom";

// sidebar nav config
import navigation from "../../_nav";
import { CSidebarStyled } from "src/components/Styled";
import SearchBarCard from "src/components/SearchBarCard/SearchBarCard";
import { SearchInput } from "src/components/CommonInputs";
import { PrimaryButton, SecondaryButton } from "src/components/Buttons/Buttons";
import TableComponent from "src/components/TableComponent/TableComponent";
import performRequest from "src/common/network";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { ManageInspectionWrapper } from "../ManageInspectionTypes/Styled";
import Pagination from "react-responsive-pagination";
import useDebounce from "src/hooks/useDebounce";

export const CFormInputStyled = styled(CFormInput)`
  border-color: #dadada;
  border-radius: 10px;
  height: 54px;
  font-size: 16px;
  color: #909090;
  width: 100% !important;

  border-bottom-right-radius: 10px !important;
  border-top-right-radius: 10px !important;

  @media (max-width: 1599px) {
    height: 46px;
    font-size: 14px;
  }
`;
const ManageCusotmer = () => {
  const unfoldable = useSelector((state) => state.sidebarUnfoldable);
  const { sidebarShow } = useSelector((state) => state.common);
  const [visible, setVisible] = useState(false);
  const [newTable, setNewtable] = useState(false);
  const [addCustomerModal, _addCustomerModal] = useState(false);
  const [file, setFile] = useState();
  const [editcustomer, _editcustomer] = useState({});
  const [output, setoutput] = useState([]);
  const [tableData, _tableData] = useState([]);
  const [dataList, _dataList] = useState([]);
  const [offset, _offset] = useState(0);
  const [limit, _limit] = useState(10);
  const [total, _total] = useState(0);
  const [searchData, _searchData] = useState("");
  const [sort, setSort] = useState({ sortField: "_id", sortOrder: "asc" });
  const [paginationData, _paginationData] = useState({
    totalPages: 0,
    page: 0,
    nextPage: 0,
  });
  const { token } = useSelector((s) => s.user);
  const fileReader = new FileReader();
  const debouncedSearch = useDebounce(searchData, 500);

  useEffect(() => {
    getCustomerlist();
  }, [offset, debouncedSearch, sort]);

  const actionsHandler = () => {
    return (
      <>
        {/* <Link to="/addUser"> */}
        {/* <SecondaryButton onClick={() => setVisible(!visible)} text="Add Representative" /> */}
        {/* </Link> */}
        <SearchInput
          onChange={(e) => {
            _searchData(e.target.value);
          }}
          onSubmit={() => (!offset ? getCustomerlist() : _offset(0))}
        />
      </>
    );
  };

  const csvToFile = (text) => {
    const csvHeader = text.slice(0, text.indexOf("\n")).split(",");
    console.log("csvHeader", csvHeader);
    const csvRows = text.slice(text.indexOf("\n") + 1).split("\n");
    console.log("csvRows", csvRows);

    const outputData = csvRows?.map((item) => {
      console.log("textdf", item);
      const values = item.split(",");
      const obj = csvHeader?.reduce((object, header, index) => {
        object[header.trim("\r")] = values[index].trim("\r");
        return object;
      }, {});
      return obj;
    });
    console.log("outputData", outputData);
    setoutput(outputData);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (file) {
      fileReader.onload = (event) => {
        const csvOutput = event.target.result;
        console.log("csvOutput", csvOutput);
        csvToFile(csvOutput);
      };
      fileReader.readAsText(file);
      setNewtable(true);
    } else {
      toast.error("Please Select file");
    }
    setVisible(false);
  };
  console.log("file", file);

  const headerKeys = Object.keys(Object.assign({}, ...output));

  const saveCustomer = async () => {
    try {
      let data = output?.map((d) => {
        let temp = {
          facalityName: d["Facility Name"],
          contact: d["Contact"],
          address: d["Address"],
          customerName: d["Customer Name"],
          email: d["Email"],
          phone: d["Phone"],
        };
        return temp;
      });
      let response = await performRequest(
        "customer/addcustomer",
        data,
        token,
        "POST"
      );
      if (response.body) {
        if (response.body.status) {
          toast.success("Customer added successfully");
          setNewtable(false);
          getCustomerlist();
        } else {
          toast.error(response.body.message);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getCustomerlist = async () => {
    try {
      const params = {
        offset,
        limit,
        search: debouncedSearch,
        ...sort,
      };
      let url = `customer/customerlist?${new URLSearchParams(
        params
      ).toString()}`;

      let response = await performRequest(url, "", token, "GET");
      console.log("response body", response?.body);
      if (response?.body) {
        _total(response.body.data.totalDocs || 0);
        _paginationData({
          nextPage: response.body.data.nextPage,
          page: response.body.data.page,
          totalPages: response.body.data.totalPages,
        });
        if (response?.body?.data?.docs.length) {
          let newCustomerList = response?.body?.data?.docs?.map((items) => {
            console.log("items", items);
            return {
              ...items,
              action: <GetActionButton item={items} />,
            };
          });
          console.log("newCustomerList", newCustomerList);
          _dataList(newCustomerList);
        }
      }
    } catch (e) {
      console.log("eeee", e);
      token && toast.error("Something went wrong. Try again");
    }
  };

  const FormModal = memo(({ edit }) => {
    const [editData, _editData] = useState({});
    console.log("edit", edit);
    const {
      handleSubmit,
      register,
      formState: { errors, isSubmitting },
      reset,
    } = useForm({
      defaultValues: {
        facalityName: edit?.facalityName || "",
        contact: edit?.contact || "",
        address: edit?.address || "",
        customerName: edit?.customerName || "",
        email: edit?.email || "",
        phone: edit?.phone || "",
      },
    });
    const onSubmit = async (value) => {
      console.log("value", value);
      if (editcustomer?._id) {
        try {
          console.log("values", value);
          let response = await performRequest(
            "customer/editcustomer/" + editcustomer?._id,
            [value],
            token,
            "PUT"
          );
          if (response.body) {
            if (response.body.status) {
              toast.success("Customer edit successfully");
              _editcustomer({});
              reset();
              _addCustomerModal(false);
              getCustomerlist();
            } else {
              toast.error(response.body.message);
            }
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          let response = await performRequest(
            "customer/addcustomer",
            [value],
            token,
            "POST"
          );
          if (response.body) {
            if (response.body.status) {
              toast.success("Customer added successfully");
              reset();
              _addCustomerModal(false);
              getCustomerlist();
            } else {
              toast.error(response.body.message);
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    };
    return (
      <CModal
        alignment="center"
        visible={addCustomerModal}
        backdrop="static"
        onClose={() => {
          _addCustomerModal(false);
          _editcustomer({});
          reset();
        }}
      >
        <CModalHeader>
          <CModalTitle>Add Customer</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <form onSubmit={handleSubmit((v) => onSubmit(v))}>
            <CRow>
              <CCol xs={12} xl={12}>
                <CInputGroup className="mb-4 flex-column">
                  <CFormInputStyled
                    type="text"
                    placeholder="Facility Name"
                    onChange={(e) => {
                      console.log(e.target.value);
                    }}
                    {...register("facalityName", {
                      required: "facalityName is required",
                      minLength: {
                        value: 2,
                        message: "Minimum length should be 2",
                      },
                    })}
                  />
                  <span className="d-flex mt-2 error_color">
                    {errors.facalityName && errors.facalityName.message}
                  </span>
                </CInputGroup>
                <CInputGroup className="mb-4 flex-column">
                  <CFormInputStyled
                    type="text"
                    placeholder="Contact"
                    {...register("contact", {
                      required: "contact is required",
                    })}
                  />
                  <span className="d-flex mt-2 error_color">
                    {errors.contact && errors.contact.message}
                  </span>
                </CInputGroup>
                <CInputGroup className="mb-4 flex-column">
                  <CFormInputStyled
                    type="text"
                    placeholder="Address"
                    {...register("address", {
                      required: "address is required",
                      minLength: {
                        value: 2,
                        message: "Minimum length should be 2",
                      },
                    })}
                  />
                  <span className="d-flex mt-2 error_color">
                    {errors.address && errors.address.message}
                  </span>
                </CInputGroup>
                <CInputGroup className="mb-4 flex-column">
                  <CFormInputStyled
                    type="text"
                    placeholder="Customer Name"
                    {...register("customerName", {
                      required: "customerName is required",
                      minLength: {
                        value: 2,
                        message: "Minimum length should be 2",
                      },
                    })}
                  />
                  <span className="d-flex mt-2 error_color">
                    {errors.customerName && errors.customerName.message}
                  </span>
                </CInputGroup>
                <CInputGroup className="mb-4 flex-column">
                  <CFormInputStyled
                    type="text"
                    placeholder="Email"
                    {...register("email", {
                      required: "email is required",
                    })}
                  />
                  <span className="d-flex mt-2 error_color">
                    {errors.email && errors.email.message}
                  </span>
                </CInputGroup>
                <CInputGroup className="mb-4 flex-column">
                  <CFormInputStyled
                    type="number"
                    placeholder="Phone"
                    {...register("phone", {
                      required: "phone is required",
                      minLength: {
                        value: 2,
                        message: "Minimum length should be 2",
                      },
                    })}
                  />
                  <span className="d-flex mt-2 error_color">
                    {errors.phone && errors.phone.message}
                  </span>
                </CInputGroup>
              </CCol>
              <PrimaryButton text="Save" type="submit" />
            </CRow>
          </form>
        </CModalBody>
        {/* <CModalFooter>
        <SecondaryButton text="cancel" onClick={() => _addCustomerModal(false)} />
        <PrimaryButton text="save" onClick={() => saveCustomer()} />
      </CModalFooter> */}
      </CModal>
    );
  });

  const deleteuser = async (id) => {
    try {
      let res = await performRequest(
        "customer/customerlist/" + id,
        "",
        token,
        "DELETE"
      );
      console.log("ressss", res);
      if (res.body.status) {
        toast.success("Deleled successfully");
        getCustomerlist();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const tableColumnData = [
    {
      dataField: "facalityName",
      text: "Facality Name",
      sort: true,
      headerStyle: () => {
        return { width: "15%" };
      },
    },
    {
      dataField: "contact",
      text: "Contact",
      sort: true,
      headerStyle: () => {
        return { width: "15%" };
      },
    },
    {
      dataField: "address",
      text: "Address",
      sort: false,
      align: "left",
      headerStyle: () => {
        return { width: "30%", align: "left" };
      },
    },
    {
      dataField: "customerName",
      text: "Customer Name",
      sort: true,
      align: "left",
      headerStyle: () => {
        return { width: "30%", align: "left" };
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      align: "left",
      headerStyle: () => {
        return { width: "30%", align: "left" };
      },
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: false,
      align: "left",
      headerStyle: () => {
        return { width: "30%", align: "left" };
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
  ];
  const tableHeader = [
    "Facality Name",
    "Contact",
    "Address",
    "customer Name",
    "Email",
    "Phone",
  ];

  const GetActionButton = ({ item }) => {
    return (
      <CDropdown>
        <CDropdownToggle
          color="secondary"
          style={{
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            color: "white",
          }}
          caret={false}
        >
          ...
        </CDropdownToggle>
        <CDropdownMenu>
          <CDropdownItem
            onClick={() => {
              console.log(item);
              _editcustomer(item);
              _addCustomerModal(true);
            }}
            style={{ cursor: "pointer" }}
          >
            Edit
          </CDropdownItem>
          <CDropdownItem
            onClick={() => deleteuser(item._id)}
            style={{ cursor: "pointer" }}
          >
            Remove
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    );
  };

  const onTableChange = (type, newState) => {
    console.log(type, newState);
    switch (type) {
      case "sort":
        setSort({
          sortField: newState?.sortField,
          sortOrder: newState?.sortOrder,
        });
        break;

      default:
        break;
    }
  };

  return (
    <>
      <SearchBarCard
        text="Manage Customers"
        actions={actionsHandler()}
      ></SearchBarCard>
      <div className="d-flex justify-content-end my-3">
        <SecondaryButton
          style={{ marginRight: 15 }}
          text="Import"
          onClick={() => setVisible(true)}
        />
        <SecondaryButton
          text="Add Customer"
          onClick={() => _addCustomerModal(true)}
        />
      </div>
      <CModal
        alignment="center"
        visible={visible}
        backdrop="static"
        onClose={() => setVisible(false)}
      >
        <CModalBody>
          <input
            type={"file"}
            accept={".csv"}
            onChange={(e) => setFile(e.target.files[0])}
          />
        </CModalBody>
        <CModalFooter>
          <div>
            <SecondaryButton
              text="Cancel"
              style={{ marginRight: 15 }}
              onClick={() => setVisible(!visible)}
            />
            <PrimaryButton text="Ok" onClick={(e) => handleOnSubmit(e)} />
          </div>
        </CModalFooter>
      </CModal>
      <CModal
        className="csvTable"
        size="xl"
        alignment="center"
        visible={newTable}
        onClose={() => setNewtable(false)}
      >
        <CModalBody>
          <table
            style={{
              width: "100%",
              background: "#e82225",
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
          >
            <thead>
              <tr key={"header"}>
                {headerKeys?.map(
                  (key) => (
                    console.log("key", key),
                    (<th style={{ padding: 15, color: "#fff" }}>{key}</th>)
                  )
                )}
              </tr>
            </thead>
            <tbody style={{ background: "#fff" }}>
              {output.map(
                (item, index) => (
                  console.log("item", item),
                  (
                    <tr
                      key={item.id}
                      style={{
                        verticalAlign: "baseline",
                        borderBottom: "1px solid #d8dbe0",
                      }}
                    >
                      {Object.values(item).map((val) => (
                        <td
                          style={{
                            padding: "15px 10px",
                            color: "#909090",
                            verticalAlign: "middle",
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "13px",
                            lineHeight: "24px",
                          }}
                        >
                          {val}
                        </td>
                      ))}
                      <td style={{ padding: "10px" }}>
                        <SecondaryButton
                          text="Delete"
                          onClick={() =>
                            setoutput((s) => {
                              let temp = [...s];
                              temp.splice(index, 1);
                              return temp;
                            })
                          }
                        />
                      </td>
                    </tr>
                  )
                )
              )}
            </tbody>
          </table>
        </CModalBody>
        <CModalFooter>
          <div>
            {/* <SecondaryButton text="cancel" style={{ marginRight: 15 }} onClick={() => setVisible(false)} /> */}
            <PrimaryButton text="Save" onClick={() => saveCustomer()} />
          </div>
        </CModalFooter>
      </CModal>
      <FormModal edit={editcustomer} />
      <div>
        <TableComponent
          tableColumn={tableColumnData}
          tableData={dataList}
          remote={{
            filter: false,
            pagination: true,
            sort: true,
            cellEdit: false,
          }}
          onTableChange={onTableChange}
        />
        <ManageInspectionWrapper>
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex align-items-center">
              <p className="mb-0">
                {total} Customer (showing {offset + 1}-
                {offset + limit < total ? offset + limit : total})
              </p>
            </div>
            <div className="customer-pagination">
              <Pagination
                current={paginationData.page}
                total={paginationData.totalPages}
                onPageChange={(d) => {
                  console.log("dfdf", d);
                  console.log("limit", limit);
                  _offset((d - 1) * limit);
                }}
              />
            </div>
          </div>
        </ManageInspectionWrapper>
      </div>
    </>
  );
};

export default ManageCusotmer;

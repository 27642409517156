import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import performRequest, { performRequestFormData } from "src/common/network";
import TableComponent from "src/components/TableComponent/TableComponent";
import { GlobalVar } from "src/common/globalVar";
import {
  CAvatar,
  CBadge,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormCheck,
  CFormSelect,
  CInputGroup,
  CListGroup,
  CListGroupItem,
  CModalBody,
  CPopover,
  CRow,
  CSpinner,
} from "@coreui/react";
import styledComponents from "styled-components";
import SimpleCard from "src/components/SimpleCard/SimpleCard";
import { PrimaryButton, SecondaryButton } from "src/components/Buttons/Buttons";
import { CModal, CModalHeader, CModalTitle } from "@coreui/react";
import { useForm } from "react-hook-form";

import {
  CFormInputStyled,
  CFormNewTextareaStyled,
  FormWrapper,
} from "../ManageInspectionTypes/InspectionTypesAddForm";
import _ from "lodash";
import { cilCheckCircle } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

const Avatar = styledComponents.div`
  .user-img {
    margin: 5px;
    width: 100px;
    height: 100px;
  }
  .user-img .avatar-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius:unset;
  }
`;
const Popimages = styledComponents.div`


  .avatar {
    text-align: center;
    border-radius: unset;
  }
  .avatar .avatar-img {
    width: 100%;
    border-radius: unset;
}
`;
const BankTables = styledComponents.div`
  .table-hover td{
    white-space: unset !important;
  }
`;
const ShowInspection = () => {
  const [dataList, _dataList] = useState([]);
  const [inspectionPlace, _inspectionPlace] = useState([]);
  const [inspectionPart, _inspectionPart] = useState([]);
  const [partsData, _partsData] = useState([]);
  const [inspectionResponse, _inspectionResponse] = useState({});
  const [productInformation, _productInformation] = useState();
  const [selectedInspection, _selectedInspection] = useState({});
  const [selectedInsectionId, _selectedInsectionId] = useState();
  const [editNotesIndex, _editNotesIndex] = useState();
  const [bankEditIndex, _bankEditIndex] = useState();
  const [editCommentIndex, _editCommentIndex] = useState();
  const [editFacilitytIndex, _editFacilitytIndex] = useState();
  const [getID, _getID] = useState([]);
  const [editNotesID, _editNotesID] = useState();
  const [locationName, _locationName] = useState();
  const [tableColumn, _tableColumn] = useState([]);
  const [popImage, _popImage] = useState({});
  const [imgHeight, _imgHeight] = useState();
  const [imgWidth, _imgwidth] = useState();
  const [visible, setVisible] = useState(false);
  const [editModal, _editModal] = useState(false);
  const [bankModal, _bankModal] = useState(false);
  const [commentModal, _commentModal] = useState(false);
  const [facilityModal, _facilityModal] = useState(false);
  const { token } = useSelector((s) => s.user);
  const params = useParams();
  const location = useLocation();
  const [editednotes, _editedNotes] = useState("");
  const [editedTechnotes, _editedTechnotes] = useState("");
  const [editedNames, _editedNames] = useState("");
  const [editedStatus, _editedStatus] = useState("");
  const [editComment, _editComment] = useState({
    comment: "",
    privatecomment: "",
  });
  const [editFacility, _editFacility] = useState({
    facalityName: "",
    contact: "",
    Address: "",
    customerName: "",
    phone: "",
    email: "",
    productInfo: {},
  });
  const [editPhoto, _editPhoto] = useState([]);
  const [editEquipementInfo, _editEquipementInfo] = useState("");
  const [imageUpload, _imageUpload] = useState([]);
  const [imageName, _imageName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [equipmentData, _equipmentData] = useState({
    location: "",
    data: [],
    _id: "",
  });
  const [CopyequipmentData, _CopyequipmentData] = useState({
    location: "",
    data: [],
    _id: "",
  });
  const [other, _other] = useState("");
  const [otherIndex, _otherIndex] = useState();
  const { userData } = useSelector((s) => s.user);
  const [inspectionListTbl, _inspectionListTbl] = useState([]);
  const inputRef = useRef(null);
  console.log("params", params);
  console.log("imgUpadate", imgWidth, imgHeight);

  useEffect(() => {
    if (getID?.length > 0) {
      dataConfig();
      const FilteredLineItems =
        inspectionResponse?.body?.data?.docs?.[0]?.lineItemsData?.find((s) =>
          getID.includes(s.equimentinfo)
        );
      _selectedInspection(FilteredLineItems);
    } else {
      console.log("No Get ID");
      _inspectionPart([]);
    }
  }, [getID, locationName]);

  useEffect(() => {
    ShowInspectionlist();
  }, []);

  useEffect(() => {
    if (inspectionResponse) {
      if (getID?.length) {
        dataConfig();
      } else {
        console.log(
          "setting",
          inspectionResponse?.body?.data?.docs?.[0]?.equimentinfoData[0]
        );
        _getID([
          inspectionResponse?.body?.data?.docs?.[0]?.equimentinfoData[0]?.id,
          inspectionResponse?.body?.data?.docs?.[0]?.equimentinfoData[0]?._id,
        ]);
        _locationName(
          inspectionResponse?.body?.data?.docs?.[0]?.equimentinfoData[0]
            ?.location
        );
      }
    }
  }, [inspectionResponse]);

  useEffect(() => {
    if (selectedInspection) {
      _selectedInsectionId(0);
      // dataConfig()
      console.log("selectedInspection", selectedInspection);
    }
  }, [selectedInspection]);

  useEffect(() => {
    _equipmentData({
      ...inspectionResponse?.body?.data?.docs[0].equimentinfoData[
        bankEditIndex
      ],
    });
    _CopyequipmentData({
      ...inspectionResponse?.body?.data?.docs[0].equimentinfoData[
        bankEditIndex
      ],
    });
  }, [bankEditIndex]);

  const onRowClickeq = {
    onClick: (e, row, rowIndex) => {
      // e.stopPropagation();
      console.log("rowIndex", row);
      _getID([row?._id, row?.id]);
      console.log("rowIndex", row?.location);
      _locationName(row?.location);
    },
  };
  const ShowInspectionlist = async (isFirstLoad = true) => {
    try {
      const response = await performRequest(
        "inspection/inspectionlist/" + params?.id,
        "",
        token,
        "GET"
      );
      console.log("showinspeciton", response);
      _inspectionResponse(response);
      if (isFirstLoad) {
        _getID([
          response?.body?.data?.docs?.[0]?.equimentinfoData[0]?.id,
          response?.body?.data?.docs?.[0]?.equimentinfoData[0]?._id,
        ]);
      }
      _productInformation(
        Object?.values(response?.body?.data?.docs?.[0]?.productInfo)
      );
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong. Try again");
    }
  };

  const dataConfig = () => {
    let response = inspectionResponse;
    console.log("response", response);
    if (response?.body?.data) {
      const equipmentDataInfo = _.cloneDeep(
        response?.body?.data?.docs?.[0]?.equimentinfoData[0]
      );
      const orderedData = _.orderBy(
        response?.body?.data?.docs?.[0]?.equimentinfoData[0]?.data,
        ["sort", ["asc"]]
      );
      console.log("orderedData", orderedData);
      const showinspection = orderedData?.map((item, index) => {
        console.log("temps123", item);
        let temp = {
          dataField: item?.name?.replace(/ /g, ""),
          text: item?.name,
          sort: true,
        };
        return temp;
      });
      const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
        return <BankActionButton itemIindex={rowIndex} />;
      };
      _tableColumn([
        {
          dataField: "location",
          text: "Location",
          sort: true,
        },
        ...showinspection,
        {
          dataField: "action",
          text: "Action",
          sort: false,
          isDummyField: true,
          formatter: actionFormatter,
        },
      ]);
      console.log("response?.body?.data", response?.body?.data);
      let mData = _.orderBy(response?.body?.data?.docs?.[0]?.equimentinfoData, [
        "sort",
        ["asc"],
      ]);
      const valueinspection = mData?.map((item, index) => {
        let temp = {};
        temp = {
          location: item?.location,
          id: item?.id,
          _id: item?._id,
          // action: <BankActionButton itemIindex={index} />,
        };
        item?.data?.map((itemList, index) => {
          if (itemList?.type == "GrossSeat") {
            console.log("item?.data", item?.data);
            temp[itemList?.name.replace(/ /g, "")] = Math.ceil(
              (Number(
                item?.data?.filter((s) => s.name.toLowerCase() == "length")[0]
                  ?.value
              ) *
                Number(
                  item?.data?.filter(
                    (s) => s.name.toLowerCase() == "number of tiers"
                  )[0]?.value
                )) /
                1.5
            );
            console.log(
              "Number(item?.data?.filter(s => s.name.toLowerCase() == 'length')[0]?.value)",
              Number(
                item?.data?.filter((s) => s.name.toLowerCase() == "length")[0]
                  ?.value
              )
            );
            console.log(
              "Number(item?.data?.filter(s => s.name.toLowerCase() == 'number of tyre')[0]?.value)",
              Number(
                item?.data?.filter(
                  (s) => s.name.toLowerCase() == "number of tyre"
                )[0]?.value
              )
            );
            console.log(
              "Number(item?.data?.filter(s => s.name.toLowerCase() == 'number of tires')[0]?.value))",
              Number(
                item?.data?.filter(
                  (s) => s.name.toLowerCase() == "number of tiers"
                )[0]?.value
              )
            );
          } else {
            temp[itemList?.name?.replace(/ /g, "")] = itemList?.value;
          }
          console.log("itemList temp", temp);
          return itemList;
        });
        return temp;
      });
      _dataList(valueinspection);

      console.log("bankData", valueinspection);

      const newFilterData = response?.body?.data?.docs?.[0]?.lineItemsData;
      console.log("newFilterData", newFilterData);
      const dataf = response?.body?.data?.docs?.[0]?.lineItemsData?.map(
        (i, k) => {
          let location = valueinspection.find((f) =>
            [f._id, f.id].includes(i.equimentinfo)
          )?.location;
          console.log("locationdf", location);
          const place = i?.lineItems?.map((item, index) => {
            console.log("itemssd", item);
            console.log("itemssddf", index);
            const imageList = (
              <div className="d-flex align-items-center flex-wrap">
                {item?.images?.map((imageItem, index) => {
                  return (
                    <Avatar style={{ position: "relative" }}>
                      <CAvatar
                        width={100}
                        height={100}
                        className="user-img position-relative"
                        style={{ borderRadius: "none" }}
                        src={GlobalVar.inspectionImageEndpoint + imageItem}
                        onClick={(e) => {
                          console.log("eeeeeeee", e);
                          setVisible(true);
                          _popImage(e.target.src);
                          _imgHeight(e.target.naturalHeight);
                          _imgwidth(e.target.naturalWidth);
                        }}
                      ></CAvatar>
                    </Avatar>
                  );
                })}
              </div>
            );
            let temp = {
              ...item,
              location: location,
              photo: imageList,
              action: (
                <GetActionButton
                  itemIindex={index}
                  item={item}
                  inspection={i}
                />
              ),
            };
            return temp;
          });
          return { ...i, lineItems: place, location };
        }
      );
      console.log("dataf", dataf);
      _inspectionListTbl(dataf);

      let data = [];
      console.log("datafsd", dataf);

      for (const items of dataf) {
        for (const item of items?.lineItems) {
          console.log("itemsd", item);
          let temp = item?.equiment?.filter(
            (i) => i.qty != "" && i.parts != ""
          );
          if (temp?.length) {
            temp = temp.map((i) => {
              return {
                ...i,
                id: items.equimentinfo,
                itemId: item._id,
                name: item.name,
                bank: item.location,
              };
            });
            data.push(...temp);
          }
        }
      }
      console.log("parts needed", data);
      _partsData(data);

      // if (FilteredLineItems != -1) {
      //     const newPlace = newFilterData?.map((items, index) => {

      //         console.log("placea", place)
      //         //     _inspectionPlace(items)
      //         return place;
      //     })
      //     console.log("inspaectionRow", newPlace)

      //     // const abc = newPlace?.map((items, index) => {
      //     //     // console.log("itemsDatatatat",items)
      //     // })
      // }
      // else {
      //     _inspectionPlace([])
      // }
    }
  };

  console.log("What is this off the side of the page?", dataList);
  // console.log("deleteData", deleteData)

  console.log("bankEditIndex", productInformation);

  const DownloadReport = async () => {
    setLoading(true);
    const response = await performRequest(
      "pdf/inspectionreport",
      { id: params?.id, type: "report" },
      token,
      "POST"
    );
    if (response?.body?.status) {
      const link = document.createElement("a");
      link.download = true;
      link.target = "_blank";
      link.href = GlobalVar.insepectionPDF + response?.body?.data;
      document.body.appendChild(link);
      setLoading(false);
      link.click();
      document.body.removeChild(link);
    } else {
      setLoading(false);
      toast.error("Something went wrong. Try again");
    }
  };
  const DownloadInspectionReport = async () => {
    setLoading(true);
    const response = await performRequest(
      "pdf/inspectionreport",
      { id: params?.id, type: "certificate" },
      token,
      "POST"
    );
    if (response?.body?.status) {
      setLoading(false);
      const link = document.createElement("a");
      link.download = true;
      link.target = "_blank";
      link.href = GlobalVar.insepectionReport + response?.body?.data;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      setLoading(false);
      toast.error("Something went wrong. Try again");
    }
  };
  const SendEmail = async () => {
    const response = await performRequest(
      "pdf/inspectionreport",
      { id: params?.id, type: "email" },
      token,
      "POST"
    );
    if (response?.body?.status) {
      toast.success("Email Sent SuceessFully");
    } else {
      toast.error("Something went wrong. Try again");
    }
  };

  const statusChangeAction = async () => {
    const res = await performRequest(
      "inspection/inspectionlist/" + params?.id,
      { status: "completed" },
      token,
      "PUT"
    );
    if (res.body.status) {
      toast.success("completed Sucessfully");
    } else {
      toast.error("Something went wrong. Try again");
    }
  };
  const GetActionButton = ({ itemIindex, item, inspection }) => {
    return (
      <CDropdown>
        <CDropdownToggle
          color="secondary"
          style={{
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            color: "white",
          }}
          caret={false}
        >
          ...
        </CDropdownToggle>
        <CDropdownMenu>
          <CDropdownItem
            onClick={() => {
              console.log("inspectiondfdfdf", inspection);
              _inspectionPlace(inspection.lineItems);
              _editModal(true);
              _editNotesIndex(itemIindex);
              console.log("editNotesIndex", itemIindex);
              console.log("inspectionPlace", inspectionPlace);
              console.log("itemsds", item.TaskStaus);
              _editedNames(item?.name);
              _editedStatus(item?.TaskStaus);
              _editedNotes(item?.notes);
              _editedTechnotes(item?.techNotes);
              _editPhoto(item?.images);
              _editEquipementInfo(inspection.equimentinfo);
              console.log("/**-*-*-*--", item);
            }}
            style={{ cursor: "pointer" }}
          >
            Edit
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    );
  };

  const BankActionButton = ({ itemIindex }) => {
    return (
      <CDropdown>
        <CDropdownToggle
          color="secondary"
          style={{
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            color: "white",
          }}
          caret={false}
        >
          ...
        </CDropdownToggle>
        <CDropdownMenu>
          <CDropdownItem
            onClick={() => {
              _bankModal(true);
              _bankEditIndex(itemIindex);
            }}
            style={{ cursor: "pointer" }}
          >
            Edit
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    );
  };

  const CommentActionButton = ({ itemIindex }) => {
    return (
      <CDropdown>
        <CDropdownToggle
          color="secondary"
          style={{
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            color: "white",
          }}
          caret={false}
        >
          ...
        </CDropdownToggle>
        <CDropdownMenu>
          <CDropdownItem
            onClick={() => {
              _commentModal(true);
              _editCommentIndex(itemIindex);
              _editComment({
                comment:
                  inspectionResponse?.body?.data?.docs[itemIindex].comment,
                privatecomment:
                  inspectionResponse?.body?.data?.docs[itemIindex]
                    .privatecomment,
              });
              // _editPrivateComment(inspectionResponse?.body?.data?.docs[itemIindex].privatecomment)
              console.log(
                "/**-*-*-*--",
                inspectionResponse?.body?.data?.docs[itemIindex].privatecomment
              );
            }}
            style={{ cursor: "pointer" }}
          >
            Edit
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    );
  };

  const FacilityActionButton = ({ itemIindex }) => {
    return (
      <CDropdown>
        <CDropdownToggle
          color="secondary"
          style={{
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            color: "white",
          }}
          caret={false}
        >
          ...
        </CDropdownToggle>
        <CDropdownMenu>
          <CDropdownItem
            onClick={() => {
              _facilityModal(true);
              _editFacilitytIndex(itemIindex);
              _editFacility({
                facalityName:
                  inspectionResponse?.body?.data?.docs[itemIindex]
                    ?.facalityName ||
                  inspectionResponse?.body?.data?.docs[itemIindex]?.member
                    ?.facalityName,
                Address:
                  inspectionResponse?.body?.data?.docs[itemIindex].Address,
                contact:
                  inspectionResponse?.body?.data?.docs[itemIindex].contact,
                customerName:
                  inspectionResponse?.body?.data?.docs[itemIindex].customerName,
                email: inspectionResponse?.body?.data?.docs[itemIindex].email,
                phone: inspectionResponse?.body?.data?.docs[itemIindex].phone,
              });
              // _editPrivateComment(inspectionResponse?.body?.data?.docs[itemIindex].privatecomment)
              console.log(
                "/**-*-*-*--",
                inspectionResponse?.body?.data?.docs[itemIindex].privatecomment
              );
            }}
            style={{ cursor: "pointer" }}
          >
            Edit
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    );
  };
  console.log("editedStatus", editedStatus);
  const hasPartNeededFormatter = (cell, row, rowIndex, formatExtraData) => {
    console.log("hasPartNeededFormatter", row.equiment);
    const partsNeededData = row.equiment?.filter(
      (i) => i.qty != "" && i.parts != ""
    );

    const PartsList = () => {
      return (
        <CListGroup flush>
          {partsNeededData.map((part, idx) => {
            return (
              <CListGroupItem
                key={idx}
                className="d-flex justify-content-between align-items-center"
              >
                {part.parts}
                <CBadge color="danger" shape="rounded-pill">
                  {part.qty}
                </CBadge>
              </CListGroupItem>
            );
          })}
        </CListGroup>
      );
    };

    return (
      <>
        {partsNeededData?.length > 0 ? (
          <CPopover
            title="Parts Needed"
            content={<PartsList />}
            placement="auto"
          >
            <span style={{ cursor: "pointer", color: "#e82225" }}>
              <CIcon icon={cilCheckCircle} size="xl" />
            </span>
          </CPopover>
        ) : (
          <></>
        )}
      </>
    );
  };
  const tableData = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: () => {
        return { width: "15%" };
      },
    },
    {
      dataField: "TaskStaus",
      text: "Status",
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "notes",
      text: "Notes",
      sort: true,
      align: "left",
      headerStyle: () => {
        return { width: "25%", align: "left" };
      },
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <span style={{ whiteSpace: "pre-line" }}>{row?.notes}</span>
      ),
    },
    {
      dataField: "techNotes",
      text: "Tech Notes",
      sort: true,
      align: "left",
      headerStyle: () => {
        return { width: "25%", align: "left" };
      },
    },
    {
      dataField: "photo",
      text: "Photo",
      sort: true,
      align: "left",
      headerStyle: () => {
        return { width: "40%", align: "left" };
      },
    },
    {
      dataField: "hasPartNeeded",
      text: "Part Needed",
      sort: false,
      headerStyle: () => {
        return { align: "left" };
      },
      isDummyField: true,
      formatter: hasPartNeededFormatter,
    },
    {
      dataField: "action",
      text: "Action",
      align: "left",
      headerStyle: () => {
        return { width: "20%", align: "left" };
      },
    },
  ];
  const tableParts = [
    {
      dataField: "name",
      text: "Line Item",
      sort: true,
      headerStyle: () => {
        return { width: "15%" };
      },
    },
    {
      dataField: "bank",
      text: "Bank",
      sort: true,
      headerStyle: () => {
        return { width: "15%" };
      },
    },
    {
      dataField: "qty",
      text: "Quantity",
      sort: true,
      headerStyle: () => {
        return { width: "15%" };
      },
    },
    {
      dataField: "parts",
      text: "Parts",
      sort: true,
      headerStyle: () => {
        return { width: "15%" };
      },
    },
  ];
  const onRowClickIn = {
    onClick: (e, row, rowIndex) => {
      console.log("Inspection Row", row, rowIndex);
      _selectedInsectionId(rowIndex);
      _editNotesID(row?._id);
    },
  };
  console.log("editNotesIndex", editNotesIndex);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    defaultValues: {
      name: inspectionPlace[editNotesIndex]?.name,
      TaskStaus: inspectionPlace[editNotesIndex]?.TaskStaus,
      notes: inspectionPlace[editNotesIndex]?.notes,
      techNotes: inspectionPlace[editNotesIndex]?.techNotes,
      images: inspectionPlace[editNotesIndex]?.images,
    },
  });

  const onSubmit = async (values) => {
    try {
      let lineItems = inspectionResponse?.body?.data?.docs[0].lineItemsData;
      console.log("lineItems", lineItems);
      console.log("getID", getID);
      console.log("editNotesIndex", editNotesIndex);
      let eqId = lineItems.findIndex(
        (s) => s.equimentinfo == editEquipementInfo
      );
      console.log(eqId);
      if (eqId != -1) {
        if (editedNames != inspectionPlace[editNotesIndex]?.name) {
          lineItems[eqId].lineItems[editNotesIndex].name = editedNames;
        }
        if (editedStatus != inspectionPlace[editNotesIndex]?.TaskStaus) {
          lineItems[eqId].lineItems[editNotesIndex].TaskStaus = editedStatus;
        }
        if (editednotes != inspectionPlace[editNotesIndex]?.notes) {
          lineItems[eqId].lineItems[editNotesIndex].notes = editednotes;
        }
        if (editedTechnotes != inspectionPlace[editNotesIndex]?.techNotes) {
          lineItems[eqId].lineItems[editNotesIndex].techNotes = editedTechnotes;
        }
        lineItems[eqId].lineItems[editNotesIndex].equiment =
          inspectionPlace[editNotesIndex].equiment;
        lineItems[eqId].lineItems[editNotesIndex].images = [
          ...imageUpload,
          ...editPhoto,
        ];
        const formData = new FormData();
        imageUpload.forEach((s) => {
          formData.append("images", s);
        });
        console.log("lineItems *-*-", lineItems);
        formData.append("lineItemsData", JSON.stringify(lineItems));
        console.log("formdata", formData);
        const res = await performRequestFormData(
          "inspection/inspectionlist/" + params?.id,
          formData,
          token,
          "put"
        );
        console.log("resss", res);
        ShowInspectionlist(false);
        _imageUpload([]);
        _imageName([]);
        _editModal(false);
        _editEquipementInfo("");
      }
    } catch (e) {
      console.log("e", e.message);
    }
  };
  const onSubmitBank = async () => {
    try {
      let eqData = _.cloneDeep(equipmentData);
      eqData.data = CopyequipmentData?.data;
      const res = await performRequest(
        "inspection/inspectionlist/" + params?.id,
        {
          equimentinfoData: eqData,
          equimentType: "edit",
          equimentEditId: equipmentData._id || equipmentData.id,
        },
        token,
        "PUT"
      );
      console.log("bankRes", res);
      ShowInspectionlist();
      _bankModal(false);
    } catch (e) {
      console.log("eee", e);
    }
  };

  const onSubmitComment = async () => {
    try {
      console.log("editNotesIndex", editCommentIndex);
      if (
        editComment?.comment != inspectionResponse?.body?.data?.docs[0]?.comment
      ) {
        let comment = inspectionResponse?.body?.data?.docs[0].comment;
        comment = editComment.comment;
      }
      if (
        editComment?.privatecomment !=
        inspectionResponse?.body?.data?.docs[0]?.comment
      ) {
        let privatecomment =
          inspectionResponse?.body?.data?.docs[0].privatecomment;
        privatecomment = editComment.privatecomment;
      }
      const res = await performRequest(
        "inspection/inspectioncomment/" + params?.id,
        editComment,
        token,
        "PUT"
      );
      console.log("ress", res);
      ShowInspectionlist();
      _commentModal(!commentModal);
    } catch (e) {
      console.log("eee", e);
    }
  };

  const onSubmitFacility = async () => {
    try {
      if (
        editFacility?.Address !=
        inspectionResponse?.body?.data?.docs[0]?.Address
      ) {
        let Address = inspectionResponse?.body?.data?.docs[0].Address;
        Address = editFacility.Address;
      }
      if (
        editFacility?.contact !=
        inspectionResponse?.body?.data?.docs[0]?.contact
      ) {
        let contact = inspectionResponse?.body?.data?.docs[0].contact;
        contact = editFacility.contact;
      }
      if (
        editFacility?.customerName !=
        inspectionResponse?.body?.data?.docs[0]?.customerName
      ) {
        let customerName = inspectionResponse?.body?.data?.docs[0].customerName;
        customerName = editFacility.customerName;
      }
      if (
        editFacility?.email != inspectionResponse?.body?.data?.docs[0]?.email
      ) {
        let email = inspectionResponse?.body?.data?.docs[0].email;
        email = editFacility.email;
      }
      if (
        editFacility?.phone != inspectionResponse?.body?.data?.docs[0]?.phone
      ) {
        let phone = inspectionResponse?.body?.data?.docs[0].phone;
        phone = editFacility.phone;
      }
      editFacility.productInfo = Object.assign(productInformation);
      const res = await performRequest(
        "inspection/inspectionlist/" + params?.id,
        editFacility,
        token,
        "PUT"
      );
      console.log("ress", res);
      ShowInspectionlist();
      _facilityModal(!facilityModal);
    } catch (e) {
      console.log("eee", e);
    }
  };

  function formatFileSize(bytes, decimalPoint) {
    if (bytes == 0) return "0 Bytes";
    var k = 1000,
      dm = decimalPoint || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <SimpleCard text="Facility Information">
          <div className="table-responsive">
            <table>
              <thead style={{ background: "#e82225" }}>
                <tr>
                  <th
                    style={{
                      color: "#fff",
                      padding: "15px 20px",
                      width: "15%",
                    }}
                  >
                    Facility Name
                  </th>
                  <th
                    style={{
                      color: "#fff",
                      padding: "15px 20px",
                      width: "15%",
                    }}
                  >
                    Contact
                  </th>
                  <th
                    style={{
                      color: "#fff",
                      padding: "15px 20px",
                      width: "15%",
                    }}
                  >
                    Address
                  </th>
                  <th
                    style={{
                      color: "#fff",
                      padding: "15px 20px",
                      width: "15%",
                    }}
                  >
                    Customer Name
                  </th>
                  <th
                    style={{
                      color: "#fff",
                      padding: "15px 20px",
                      width: "15%",
                    }}
                  >
                    Email
                  </th>
                  <th
                    style={{
                      color: "#fff",
                      padding: "15px 20px",
                      width: "15%",
                    }}
                  >
                    Phone
                  </th>
                  {productInformation?.map((productKey) => {
                    return (
                      <th
                        style={{
                          color: "#fff",
                          padding: "15px 20px",
                          width: "15%",
                        }}
                      >
                        {productKey?.key}
                      </th>
                    );
                  })}
                  <th
                    style={{
                      color: "#fff",
                      padding: "15px 20px",
                      width: "15%",
                    }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {inspectionResponse?.body?.data?.docs?.map((items, index) => {
                  {
                    console.log("inspectionResponse", inspectionResponse);
                  }
                  return (
                    <tr>
                      <td
                        style={{
                          color: "#909090",
                          verticalAlign: "middle",
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: 13,
                          padding: "15px 20px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {items?.facalityName || items?.member?.facalityName}
                      </td>
                      <td
                        style={{
                          color: "#909090",
                          verticalAlign: "middle",
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: 13,
                          padding: "15px 20px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {items?.contact}
                      </td>
                      <td
                        style={{
                          color: "#909090",
                          verticalAlign: "middle",
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: 13,
                          padding: "15px 20px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {items?.Address}
                      </td>
                      <td
                        style={{
                          color: "#909090",
                          verticalAlign: "middle",
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: 13,
                          padding: "15px 20px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {items?.customerName}
                      </td>
                      <td
                        style={{
                          color: "#909090",
                          verticalAlign: "middle",
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: 13,
                          padding: "15px 20px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {items?.email}
                      </td>
                      <td
                        style={{
                          color: "#909090",
                          verticalAlign: "middle",
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: 13,
                          padding: "15px 20px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {items?.phone}
                      </td>
                      {productInformation?.map((productKey) => {
                        return (
                          <td
                            style={{
                              color: "#909090",
                              verticalAlign: "middle",
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: 13,
                              padding: "15px 20px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {productKey?.value}
                          </td>
                        );
                      })}

                      <td>{<FacilityActionButton itemIindex={index} />}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </SimpleCard>
      </div>
      <div style={{ marginBottom: 20 }}>
        <SimpleCard text="Comment">
          <table>
            <thead style={{ background: "#e82225" }}>
              <tr>
                <th style={{ color: "#fff", padding: "15px 20px" }}>Comment</th>
                <th style={{ color: "#fff", padding: "15px 20px" }}>
                  Private Comment
                </th>
                <th style={{ color: "#fff", padding: "15px 20px" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {inspectionResponse?.body?.data?.docs?.map((items, index) => {
                return (
                  <tr>
                    <td
                      style={{
                        color: "#909090",
                        verticalAlign: "middle",
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: 13,
                        padding: "15px 20px",
                        wordBreak: "break-word",
                        width: "45%",
                      }}
                    >
                      {items?.comment}
                    </td>
                    <td
                      style={{
                        color: "#909090",
                        verticalAlign: "middle",
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: 13,
                        padding: "15px 20px",
                        wordBreak: "break-word",
                        width: "45%",
                      }}
                    >
                      {items?.privatecomment}
                    </td>
                    <td>{<CommentActionButton itemIindex={index} />}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </SimpleCard>
      </div>
      <div style={{ marginBottom: 20 }}>
        {tableColumn?.length ? (
          <SimpleCard text="Bank">
            <TableComponent
              tableColumn={tableColumn}
              tableData={dataList}
              rowEvents={onRowClickeq}
            />
          </SimpleCard>
        ) : (
          <></>
        )}
      </div>
      <div style={{ marginBottom: 20 }}>
        {inspectionListTbl
          ?.filter((i) => getID.includes(i.equimentinfo))
          .map((items) => {
            return (
              <div key={items.equimentinfo} style={{ marginTop: 20 }}>
                <SimpleCard text={`${items.location} inspection`}>
                  <BankTables>
                    <TableComponent
                      tableColumn={tableData}
                      tableData={items?.lineItems}
                      rowEvents={onRowClickIn}
                    />
                  </BankTables>
                </SimpleCard>
              </div>
            );
          })}
      </div>
      <SimpleCard text="Parts Needed">
        {selectedInspection?.lineItems != undefined ? (
          <TableComponent tableColumn={tableParts} tableData={partsData} />
        ) : (
          <></>
        )}
      </SimpleCard>
      <CModal
        className="image-pop-modal"
        alignment="center"
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <CModalBody>
          <Popimages
            style={{
              textAlign: "center",
              padding: 10,
              backgroundColor: "white",
            }}
          >
            <CAvatar
              style={{
                borderRadius: "none",
                maxWidth: imgWidth,
                width: "100%",
                height: "100%",
              }}
              src={popImage}
            ></CAvatar>
          </Popimages>
        </CModalBody>
      </CModal>
      <CModal
        alignment="center"
        visible={editModal}
        backdrop="static"
        onClose={() => _editModal(false)}
      >
        <CModalHeader onClose={() => _editModal(false)}>
          <CModalTitle>Edit Inspection</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            <CCol xs={12} xl={12}>
              <FormWrapper>
                <CInputGroup className="mb-4 flex-column">
                  <CFormInputStyled
                    type="text"
                    placeholder="Name"
                    value={editedNames}
                    label="Name"
                    className="mb-4"
                    onChange={(e) => {
                      _editedNames(e.target.value);
                    }}
                  />
                  <CFormNewTextareaStyled
                    type="text"
                    placeholder="Note"
                    value={editednotes}
                    label="Notes"
                    className="mb-4"
                    onChange={(e) => {
                      _editedNotes(e.target.value);
                    }}
                  />
                  <CFormInputStyled
                    type="text"
                    placeholder="Tech Note"
                    label="Tech Note"
                    value={editedTechnotes}
                    className="mb-4"
                    onChange={(e) => {
                      _editedTechnotes(e.target.value);
                    }}
                  />
                  {console.log("editedStatuseditedStatus", editedStatus)}
                  <CFormSelect
                    style={{
                      borderRadius: 10,
                      height: 50,
                      fontSize: 16,
                      color: "#909090",
                      // borderBottomRightRadius: 10,
                      // borderTopRightRadius: 10,
                      // lineHeight: 2.5
                    }}
                    className="mb-4 flex-column w-100"
                    value={editedStatus}
                    onChange={(e) => {
                      _editedStatus(e.target.value);
                    }}
                  >
                    {/* <CFormSelectStyled label="Status"> */}
                    <option
                      value="Ok"
                      // defaultChecked={true}
                    >
                      Ok
                    </option>
                    <option value="Fixed">Fixed</option>
                    <option value="Fail">Fail</option>
                    <option value="N/A">N/A</option>
                    {/* </CFormSelectStyled> */}
                  </CFormSelect>
                  <CFormInputStyled
                    type="file"
                    placeholder="Tech Note"
                    label="Add photo"
                    max="4"
                    size="1024"
                    className={`${
                      imageUpload?.length + editPhoto?.length > 3
                        ? `pe-none disable-input`
                        : "pe-auto"
                    }  mb-4 h-auto`}
                    accept="image/png, image/jpeg"
                    ref={inputRef}
                    onChange={(e) => {
                      let data = e.target.files[0];
                      console.log("data", data);
                      data.fileName = e.target.files[0].name;
                      _imageUpload([...imageUpload, data]);
                      _imageName(e.target.files[0].name);

                      // compression Code
                      // let data = e.target.files[0]
                      // console.log("e.target.files", e.target.files)
                      // new Compressor(data, {
                      //     quality: 0.7, // 0.6 can also be used, but its not recommended to go below.
                      //     success: (compressedResult) => {
                      //         console.log("compressedResult", compressedResult)
                      //         let files = new File([compressedResult], data.name, { type: "image/jpeg" })
                      //         files.fileName = data.name
                      //         console.log("filefile", files)
                      //         _imageUpload([...imageUpload, files])
                      //         _imageName(e.target.files[0].name)
                      //     },
                      // });

                      console.log("data", data);
                    }}
                  />
                  <div>
                    {imageUpload?.map((item) => {
                      return (
                        <img
                          width={100}
                          height={100}
                          src={URL.createObjectURL(item)}
                        />
                      );
                    })}
                    <div style={{ position: "relative" }}>
                      <Avatar>
                        {editPhoto?.map((items, index) => {
                          console.log("items", items);
                          return (
                            <span
                              style={{
                                position: "relative",
                                display: "inline-block",
                              }}
                            >
                              <CAvatar
                                width={100}
                                height={100}
                                className="user-img"
                                style={{ borderRadius: "none" }}
                                src={GlobalVar.inspectionImageEndpoint + items}
                              ></CAvatar>
                              <div
                                className="position-absolute"
                                onClick={() => {
                                  _editPhoto((s) => {
                                    let temp = _.cloneDeep(s);
                                    temp.splice(index, 1);
                                    return temp;
                                  });
                                }}
                                style={{
                                  width: 15,
                                  height: 15,
                                  borderRadius: "50%",
                                  right: 0,
                                  backgroundColor: "red",
                                  top: 0,
                                  cursor: "pointer",
                                }}
                              >
                                <i
                                  className="plus"
                                  style={{
                                    position: "absolute",
                                    width: 10,
                                    height: 1,
                                    right: 2,
                                    backgroundColor: "black",
                                    top: 6,
                                    transform: "rotate(58deg)",
                                  }}
                                />
                                <i
                                  className="minus"
                                  style={{
                                    position: "absolute",
                                    width: 10,
                                    height: 1,
                                    right: 2,
                                    backgroundColor: "black",
                                    top: 6,
                                    transform: "rotate(-45deg)",
                                  }}
                                />
                              </div>
                            </span>
                          );
                        })}
                      </Avatar>
                    </div>
                  </div>
                  <div
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 18,
                      fontWeight: "700",
                      color: "#E82225",
                      margin: "20px 0",
                    }}
                  >
                    Equipment
                  </div>
                  {inspectionPlace[editNotesIndex]?.equiment.map(
                    (items, index) => {
                      return (
                        <CRow>
                          <CCol xs={6}>
                            <CFormInputStyled
                              type="text"
                              placeholder="Qty"
                              value={items.qty}
                              label="Qty"
                              className="mb-4"
                              onChange={(e) => {
                                console.log(editNotesIndex);
                                _inspectionPlace((s) => {
                                  let temp = _.cloneDeep(s);
                                  temp[editNotesIndex].equiment[index].qty =
                                    e.target.value;
                                  return temp;
                                });
                              }}
                            />
                          </CCol>
                          <CCol xs={6}>
                            <CFormInputStyled
                              type="Parts"
                              placeholder="Tech Note"
                              label="Parts"
                              value={items.parts}
                              className="mb-4"
                              onChange={(e) => {
                                _inspectionPlace((s) => {
                                  let temp = _.cloneDeep(s);
                                  temp[editNotesIndex].equiment[index].parts =
                                    e.target.value;
                                  return temp;
                                });
                              }}
                            />
                          </CCol>
                        </CRow>
                      );
                    }
                  )}
                </CInputGroup>
                <div className="button_wrapper">
                  <PrimaryButton
                    text="Save"
                    type="submit"
                    onClick={() => onSubmit()}
                  />
                  <SecondaryButton
                    text="Cancel"
                    type="button"
                    onClick={() => {
                      _imageUpload([]);
                      _imageName([]);
                      _editModal(false);
                    }}
                  />
                </div>
              </FormWrapper>
            </CCol>
            <CCol xs={12} xl={6} />
          </CRow>
        </CModalBody>
      </CModal>
      <CModal
        alignment="center"
        visible={commentModal}
        backdrop="static"
        onClose={() => _commentModal(false)}
      >
        <CModalBody>
          <CRow>
            <CCol xs={12} xl={12}>
              <FormWrapper>
                <CInputGroup className="mb-4 flex-column">
                  <CFormInputStyled
                    type="text"
                    placeholder="Comment"
                    value={editComment.comment}
                    onChange={(e) =>
                      _editComment((s) => {
                        let temp = { ...s };
                        temp.comment = e.target.value;
                        console.log("comment", temp);
                        return temp;
                      })
                    }
                  />
                  <CFormInputStyled
                    type="text"
                    className="mt-4"
                    placeholder="Private Comment"
                    value={editComment.privatecomment}
                    onChange={(e) =>
                      _editComment((s) => {
                        let temp = { ...s };
                        temp.privatecomment = e.target.value;
                        console.log("comment", temp);
                        return temp;
                      })
                    }
                  />
                </CInputGroup>
                <div className="button_wrapper">
                  <PrimaryButton
                    text="Save"
                    type="submit"
                    onClick={() => onSubmitComment()}
                  />
                  {/* <SecondaryButton text="Cancel" type="button" onClick={() => history.back()} /> */}
                  <SecondaryButton
                    text="Cancel"
                    type="button"
                    onClick={() => _commentModal(false)}
                  />
                </div>
              </FormWrapper>
            </CCol>
            <CCol xs={12} xl={6} />
          </CRow>
        </CModalBody>
        {/* <CModalFooter>
                    <div>
                        <SecondaryButton text="cancel" style={{ marginRight: 15 }} onClick={() => setVisible(!visible)} />
                        <PrimaryButton text="ok" onClick={(e) => handleOnSubmit(e)} />
                    </div>
                </CModalFooter> */}
      </CModal>
      <CModal
        alignment="center"
        visible={facilityModal}
        backdrop="static"
        onClose={() => _facilityModal(false)}
      >
        <CModalHeader onClose={() => _facilityModal(false)}>
          <CModalTitle>Edit Facility</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            <CCol xs={12} xl={12}>
              <FormWrapper>
                <CInputGroup className="mb-4 flex-column">
                  <CFormInputStyled
                    type="text"
                    placeholder="Facility Name"
                    label="Facility Name"
                    value={editFacility.facalityName}
                    onChange={(e) =>
                      _editFacility((s) => {
                        let temp = { ...s };
                        temp.facalityName = e.target.value;
                        console.log("facalityName", temp);
                        return temp;
                      })
                    }
                  />
                  <CFormInputStyled
                    type="text"
                    placeholder="customer Name"
                    label="customer Name"
                    value={editFacility.customerName}
                    onChange={(e) =>
                      _editFacility((s) => {
                        let temp = { ...s };
                        temp.customerName = e.target.value;
                        console.log("customerName", temp);
                        return temp;
                      })
                    }
                  />
                  <CFormInputStyled
                    type="text"
                    placeholder="Contact"
                    label="Contact"
                    value={editFacility.contact}
                    onChange={(e) =>
                      _editFacility((s) => {
                        let temp = { ...s };
                        temp.contact = e.target.value;
                        console.log("contact", temp);
                        return temp;
                      })
                    }
                  />
                  <CFormInputStyled
                    type="text"
                    placeholder="Email"
                    label="Email"
                    value={editFacility.email}
                    onChange={(e) =>
                      _editFacility((s) => {
                        let temp = { ...s };
                        temp.email = e.target.value;
                        console.log("email", temp);
                        return temp;
                      })
                    }
                  />
                  <CFormInputStyled
                    type="text"
                    placeholder="Address"
                    label="Address"
                    value={editFacility.Address}
                    onChange={(e) =>
                      _editFacility((s) => {
                        let temp = { ...s };
                        temp.Address = e.target.value;
                        console.log("Address", temp);
                        return temp;
                      })
                    }
                  />
                  <CFormInputStyled
                    type="number"
                    placeholder="Phone"
                    label="Phone"
                    value={editFacility.phone}
                    onChange={(e) =>
                      _editFacility((s) => {
                        let temp = { ...s };
                        temp.phone = e.target.value;
                        console.log("phone", temp);
                        return temp;
                      })
                    }
                  />
                  {productInformation?.map((items, index) => {
                    return (
                      <CFormInputStyled
                        type="text"
                        placeholder="text"
                        label={items?.key}
                        value={productInformation[index].value}
                        onChange={(e) =>
                          _productInformation((s) => {
                            let temp = _.cloneDeep(s);
                            temp[index].value = e.target.value;
                            console.log("phoneDept", temp);
                            return temp;
                          })
                        }
                      />
                    );
                  })}
                </CInputGroup>
                <div className="button_wrapper">
                  <PrimaryButton
                    text="Save"
                    type="submit"
                    onClick={() => onSubmitFacility()}
                  />
                  {/* <SecondaryButton text="Cancel" type="button" onClick={() => history.back()} /> */}
                  <SecondaryButton
                    text="Cancel"
                    type="button"
                    onClick={() => _facilityModal(false)}
                  />
                </div>
              </FormWrapper>
            </CCol>
            <CCol xs={12} xl={6} />
          </CRow>
        </CModalBody>
        {/* <CModalFooter>
                    <div>
                        <SecondaryButton text="cancel" style={{ marginRight: 15 }} onClick={() => setVisible(!visible)} />
                        <PrimaryButton text="ok" onClick={(e) => handleOnSubmit(e)} />
                    </div>
                </CModalFooter> */}
      </CModal>
      <CModal
        alignment="center"
        visible={bankModal}
        backdrop="static"
        onClose={() => _bankModal(false)}
      >
        <CModalBody>
          <CRow>
            <CCol xs={12} xl={12}>
              <FormWrapper>
                <CInputGroup className="mb-4 flex-column">
                  <div className="mb-2">
                    <p className="mb-1">Location</p>
                    <CFormInputStyled
                      type="text"
                      placeholder="Location"
                      value={equipmentData.location}
                      onChange={(e) => {
                        _equipmentData((s) => {
                          let temp = { ...s };
                          temp.location = e.target.value;
                          return temp;
                        });
                      }}
                    />
                  </div>
                  {_.orderBy(equipmentData?.data, ["sort", ["asc"]])?.map(
                    (item, index) => {
                      console.log("items modal", item);
                      console.log("equipmentData modal", equipmentData);
                      return (
                        <>
                          {item.type == "Text" ||
                          item.type == "Number" ||
                          item.type == "Integer" ? (
                            <CFormInputStyled
                              className="mb-2"
                              label={item?.name}
                              value={equipmentData.data[index].value}
                              type={item.type == "Number" ? "numeric" : "text"}
                              onChange={(e) => {
                                _equipmentData((s) => {
                                  console.log("ssssssss", s);
                                  let temp = { ...s };
                                  temp.data[index].value = e.target.value;
                                  return temp;
                                });
                              }}
                              key={index.toString()}
                            />
                          ) : (
                            <></>
                          )}
                          {item.type == "List" ? (
                            <div>
                              <p
                                style={{
                                  color: "rgba(44,56,74,0.95)",
                                  backgroundColor: "#FFFFFF",
                                  zIndex: 2,
                                  fontFamily: "Roboto",
                                  fontSize: 16,
                                  fontWeight: 800,
                                  padding: "0 5px",
                                  marginBottom: 8,
                                  fontFamily: "Roboto",
                                }}
                              >
                                {item?.name}
                              </p>
                              <div className="d-flex flex-wrap mb-2">
                                {item.options?.map((itemList, i) => {
                                  console.log("itemslistsdstdsd", itemList);
                                  return (
                                    <>
                                      <CFormCheck
                                        containerStyle={{
                                          backgroundColor: "transparent",
                                          borderWidth: 0,
                                          padding: 0,
                                          margin: "5px 0",
                                        }}
                                        center
                                        className="me-2"
                                        key={i.toString()}
                                        type="radio"
                                        label={itemList}
                                        checked={
                                          itemList?.toLowerCase() == "other"
                                            ? equipmentData.data[index]
                                                ?.value &&
                                              (!item?.options?.includes(
                                                equipmentData.data[index]?.value
                                              ) ||
                                                equipmentData.data[
                                                  index
                                                ]?.value.toLowerCase() ==
                                                  "other")
                                              ? true
                                              : false
                                            : equipmentData.data[index]
                                                ?.value == itemList
                                            ? true
                                            : false
                                        }
                                        onClick={() => {
                                          _other("");
                                          _otherIndex();
                                          _equipmentData((s) => {
                                            let temp = { ...s };
                                            console.log(
                                              "temp.data[index].value",
                                              temp.data[index].value
                                            );
                                            temp.data[index].value = itemList;
                                            return temp;
                                          });
                                        }}
                                      />
                                    </>
                                  );
                                })}
                                {(equipmentData.data[index]?.value &&
                                  !item?.options?.includes(
                                    equipmentData.data[index]?.value
                                  )) ||
                                equipmentData.data[
                                  index
                                ]?.value.toLowerCase() == "other" ? (
                                  <CFormInputStyled
                                    label={"Other"}
                                    value={CopyequipmentData.data[index]?.value}
                                    keyboardType={
                                      item.type == "Number"
                                        ? "numeric"
                                        : "default"
                                    }
                                    onChange={(e) => {
                                      _other(e);
                                      _otherIndex(index);
                                      console.log("e", e);
                                      _CopyequipmentData((s) => {
                                        let temp = _.cloneDeep(s);
                                        temp.data[index].value = e.target.value;
                                        console.log(temp);
                                        return temp;
                                      });
                                    }}
                                    key={index.toString()}
                                  />
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    }
                  )}
                </CInputGroup>
                <div className="button_wrapper">
                  <PrimaryButton
                    text="Save"
                    type="submit"
                    onClick={() => onSubmitBank()}
                  />
                  <SecondaryButton
                    text="Cancel"
                    type="button"
                    onClick={() => _bankModal(false)}
                  />
                </div>
              </FormWrapper>
            </CCol>
            <CCol xs={12} xl={6} />
          </CRow>
        </CModalBody>
      </CModal>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ marginTop: 50, marginBottom: 25 }}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div style={{ marginRight: 15 }}>
            <PrimaryButton
              text="Download Report"
              onClick={() => DownloadReport()}
            />
          </div>
          <div style={{ marginRight: 15 }}>
            <SecondaryButton
              text="Download Certificate of Inspection"
              onClick={() => DownloadInspectionReport()}
              type="submit"
            />
          </div>
          {/* <div style={{ marginRight: 15 }}>
                        <SecondaryButton text="Email" onClick={() => SendEmail()} type="submit" />
                    </div> */}
          {/* <SecondaryButton text="Done" type="submit" /> */}
        </div>

        {loading ? (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              zIndex: 10000,
              height: "100vh",
              textAlign: "center",
              background: "rgba(0,0,0,0.2)",
            }}
          >
            <CSpinner style={{ position: "absolute", top: "50%" }} />
          </div>
        ) : (
          <></>
        )}
        <div className="d-flex">
          {/* <div style={{ marginRight: 15 }}>
                        <SecondaryButton text="Completed All Related" type="submit" />
                    </div> */}
          {inspectionResponse?.body?.data?.docs?.[0]?.status !== "completed" ? (
            <SecondaryButton
              text="Complete"
              type="submit"
              onClick={() => statusChangeAction()}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
export default ShowInspection;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import performRequest from "../../common/network";

export const DoLogin = createAsyncThunk("user/loginAction", async (param) => {
  try {
    let Data = {
      email: param?.email,
      password: param?.password,
    };
    const responseData = await performRequest("auth/login", Data);
    if (responseData?.body) {
      return { ...responseData?.body };
    } else {
      return { ...responseData?.error, status: false };
    }
  } catch (e) {
    throw e;
  }
});

const initialState = {
  userData: {},
  token: null,
  loading: false,
  isLogin: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state, { payload }) => {
      state.isLogin = false;
      state.token = null;
      localStorage.clear();
    },
  },
  extraReducers: {
    [DoLogin.fulfilled]: (state, action) => {
      state.loading = false;
      //   set all state
      console.log("Response", action.payload);
      let responseData = action.payload;
      if (responseData.status) {
        toast.success("Login Success");
        localStorage.setItem("token", responseData?.token);
        state.token = responseData?.token;
        state.isLogin = true;
        state.userData = responseData?.data;
      } else {
        toast.error(responseData?.message || "Invalid email or password", {
          toastId: "auth-error-message",
        });
      }
    },
  },
  [DoLogin.pending]: (state) => {
    state.loading = true;
  },
  [DoLogin.rejected]: (state, { payload }) => {
    state.loading = false;
    console.log("Response", { payload });
    toast.error(payload?.message, {
      toastId: "auth-error-message",
    });
  },
});

export const { logout } = userSlice.actions;
export default userSlice.reducer;

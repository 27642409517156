import React from 'react'
import PropTypes from 'prop-types'

const OpenInspectionsSvg = ({ height = '22', width = '34' }) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 34 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M33.7414 16.5407L30.0599 12.8594C31.1249 11.5238 31.7637 9.83412 31.7637 7.99708C31.7637 3.68809 28.2582 0.182526 23.9496 0.182526C21.5134 0.182526 19.3346 1.30358 17.9004 3.05636V1.06515C17.9004 0.577707 17.5052 0.182526 17.0178 0.182526H8.90054C8.41309 0.182526 8.01791 0.577707 8.01791 1.06515V3.96735H0.882628C0.395182 3.96735 0 4.36253 0 4.84998V20.935C0 21.4224 0.395182 21.8176 0.882628 21.8176H25.4831C25.9706 21.8176 26.3657 21.4224 26.3657 20.935V15.4273C27.2632 15.1347 28.0892 14.6839 28.8117 14.1078L32.4933 17.7891C32.6657 17.9614 32.8915 18.0475 33.1174 18.0475C33.3432 18.0475 33.5692 17.9614 33.7416 17.7889C34.0863 17.4441 34.0861 16.8854 33.7414 16.5407ZM23.9496 1.94778C27.285 1.94778 29.9984 4.66157 29.9984 7.99708C29.9984 11.3325 27.2848 14.046 23.9496 14.046C20.6141 14.046 17.9004 11.3325 17.9004 7.99708C17.9003 4.66145 20.6141 1.94778 23.9496 1.94778ZM8.90054 5.73261H16.4709C16.2533 6.44977 16.1351 7.20977 16.1351 7.99708C16.1351 10.6925 17.5071 13.0735 19.5894 14.4787V20.0524H6.77646V5.73261H8.90054ZM9.78316 1.94778H16.1351V3.96735H9.78316V1.94778ZM1.76526 5.73261H5.01121V20.0524H1.76526V5.73261ZM24.6005 20.0522H21.3545V15.3667C22.167 15.6536 23.04 15.8114 23.9496 15.8114C24.1689 15.8114 24.3857 15.8 24.6005 15.7822V20.0522Z"
          fill="white"
        />
      </svg>
    </>
  )
}

OpenInspectionsSvg.propTypes = {
  height: PropTypes.any,
  width: PropTypes.any,
}

export default OpenInspectionsSvg

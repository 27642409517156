import React from 'react'
import SimpleCard from 'src/components/SimpleCard/SimpleCard'

const AddUser = () => {
  
  return (
    <>
      <SimpleCard text="Add User"></SimpleCard>
    </>
  )
}

export default AddUser
